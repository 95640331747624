const ArrowsSelectIcon = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.46967 0.469731C0.762563 0.176838 1.23744 0.176838 1.53033 0.469731L7 5.9394L12.4697 0.469731C12.7626 0.176838 13.2374 0.176838 13.5303 0.469731C13.8232 0.762624 13.8232 1.2375 13.5303 1.53039L7.53033 7.53039C7.23744 7.82328 6.76256 7.82328 6.46967 7.53039L0.46967 1.53039C0.176777 1.2375 0.176777 0.762624 0.46967 0.469731Z"
      fill="#15181B"
    />
  </svg>
)

export default ArrowsSelectIcon
