import { useMobile } from 'src/hooks/useMobile'

type ShelfBoughtTogetherFooterProps = {
  onDeleteCard: () => void
  onReloadCard: () => void
  showButtonSeeAnother: boolean
}

export default function ShelfBoughtTogetherFooter({
  onDeleteCard,
  onReloadCard,
  showButtonSeeAnother,
}: ShelfBoughtTogetherFooterProps) {
  const { screenWidth } = useMobile()

  return (
    <footer className="mt-1 flex items-center justify-center gap-x-4 restructure-tablet:gap-x-11">
      <button
        className="flex items-center gap-2 mobile-caption-regular desktop-body-regular-text3 desktop-body-regular-text3 text-restructure-action"
        aria-label="Close"
        name="Close"
        onClick={() => onDeleteCard()}
      >
        {screenWidth >= 600 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.5 4.5C1.5 4.22386 1.72386 4 2 4H14C14.2761 4 14.5 4.22386 14.5 4.5C14.5 4.77614 14.2761 5 14 5H2C1.72386 5 1.5 4.77614 1.5 4.5Z"
              fill="#007DBC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.66634 2.3335C6.44533 2.3335 6.23337 2.42129 6.07709 2.57757C5.92081 2.73385 5.83301 2.94582 5.83301 3.16683V4.50016C5.83301 4.77631 5.60915 5.00016 5.33301 5.00016C5.05687 5.00016 4.83301 4.77631 4.83301 4.50016V3.16683C4.83301 2.6806 5.02616 2.21428 5.36998 1.87047C5.7138 1.52665 6.18011 1.3335 6.66634 1.3335H9.33301C9.81924 1.3335 10.2856 1.52665 10.6294 1.87047C10.9732 2.21428 11.1663 2.6806 11.1663 3.16683V4.50016C11.1663 4.77631 10.9425 5.00016 10.6663 5.00016C10.3902 5.00016 10.1663 4.77631 10.1663 4.50016V3.16683C10.1663 2.94582 10.0785 2.73385 9.92226 2.57757C9.76598 2.42129 9.55402 2.3335 9.33301 2.3335H6.66634ZM3.33301 4.00016C3.60915 4.00016 3.83301 4.22402 3.83301 4.50016V13.8335C3.83301 14.0545 3.92081 14.2665 4.07709 14.4228C4.23337 14.579 4.44533 14.6668 4.66634 14.6668H11.333C11.554 14.6668 11.766 14.579 11.9223 14.4228C12.0785 14.2665 12.1663 14.0545 12.1663 13.8335V4.50016C12.1663 4.22402 12.3902 4.00016 12.6663 4.00016C12.9425 4.00016 13.1663 4.22402 13.1663 4.50016V13.8335C13.1663 14.3197 12.9732 14.786 12.6294 15.1299C12.2856 15.4737 11.8192 15.6668 11.333 15.6668H4.66634C4.18011 15.6668 3.7138 15.4737 3.36998 15.1299C3.02616 14.786 2.83301 14.3197 2.83301 13.8335V4.50016C2.83301 4.22402 3.05687 4.00016 3.33301 4.00016Z"
              fill="#007DBC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.66699 7.3335C6.94313 7.3335 7.16699 7.55735 7.16699 7.8335V11.8335C7.16699 12.1096 6.94313 12.3335 6.66699 12.3335C6.39085 12.3335 6.16699 12.1096 6.16699 11.8335V7.8335C6.16699 7.55735 6.39085 7.3335 6.66699 7.3335Z"
              fill="#007DBC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.33301 7.3335C9.60915 7.3335 9.83301 7.55735 9.83301 7.8335V11.8335C9.83301 12.1096 9.60915 12.3335 9.33301 12.3335C9.05687 12.3335 8.83301 12.1096 8.83301 11.8335V7.8335C8.83301 7.55735 9.05687 7.3335 9.33301 7.3335Z"
              fill="#007DBC"
            />
          </svg>
        )}
        Remover
      </button>
      {screenWidth < 600 && showButtonSeeAnother && (
        <div className="text-restructure-tertiary">|</div>
      )}
      {showButtonSeeAnother && (
        <button
          className="flex items-center gap-2 mobile-caption-regular desktop-body-regular-text3 desktop-body-regular-text3 text-restructure-action"
          aria-label="Reload"
          name="Reload"
          onClick={() => onReloadCard()}
        >
          {screenWidth >= 600 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.333 2.6665C15.6092 2.6665 15.833 2.89036 15.833 3.1665V7.1665C15.833 7.44265 15.6092 7.6665 15.333 7.6665H11.333C11.0569 7.6665 10.833 7.44265 10.833 7.1665C10.833 6.89036 11.0569 6.6665 11.333 6.6665H14.833V3.1665C14.833 2.89036 15.0569 2.6665 15.333 2.6665Z"
                fill="#007DBC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.166992 9.8335C0.166992 9.55735 0.39085 9.3335 0.666992 9.3335H4.66699C4.94313 9.3335 5.16699 9.55735 5.16699 9.8335C5.16699 10.1096 4.94313 10.3335 4.66699 10.3335H1.16699V13.8335C1.16699 14.1096 0.943135 14.3335 0.666992 14.3335C0.39085 14.3335 0.166992 14.1096 0.166992 13.8335V9.8335Z"
                fill="#007DBC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.52486 3.21805C8.63237 2.95989 7.68903 2.93203 6.78286 3.13707C5.87669 3.3421 5.03723 3.77334 4.3428 4.39055C3.64838 5.00777 3.12162 5.79083 2.81168 6.66669C2.71956 6.92701 2.43385 7.06337 2.17353 6.97125C1.91321 6.87913 1.77685 6.59341 1.86897 6.33309C2.23526 5.29799 2.85779 4.37255 3.67847 3.64311C4.49916 2.91368 5.49125 2.40403 6.56218 2.16172C7.6331 1.91941 8.74796 1.95234 9.80272 2.25743C10.8549 2.56178 11.8131 3.12707 12.5883 3.90077L15.676 6.80218C15.8773 6.99127 15.8871 7.3077 15.698 7.50894C15.5089 7.71018 15.1925 7.72003 14.9913 7.53093L11.8979 4.62426C11.8941 4.62069 11.8904 4.61705 11.8867 4.61335C11.2299 3.95623 10.4173 3.4762 9.52486 3.21805ZM0.302617 9.49083C0.491713 9.28959 0.808142 9.27975 1.00938 9.46884L4.10272 12.3755C4.10652 12.3791 4.11027 12.3827 4.11397 12.3864C4.77076 13.0435 5.58331 13.5236 6.4758 13.7817C7.36829 14.0399 8.31162 14.0677 9.21779 13.8627C10.124 13.6577 10.9634 13.2264 11.6578 12.6092C12.3523 11.992 12.879 11.2089 13.189 10.3331C13.2811 10.0728 13.5668 9.93641 13.8271 10.0285C14.0874 10.1206 14.2238 10.4064 14.1317 10.6667C13.7654 11.7018 13.1429 12.6272 12.3222 13.3567C11.5015 14.0861 10.5094 14.5957 9.43848 14.8381C8.36755 15.0804 7.2527 15.0474 6.19794 14.7424C5.14574 14.438 4.18756 13.8727 3.41235 13.099L0.324605 10.1976C0.123366 10.0085 0.113521 9.69207 0.302617 9.49083Z"
                fill="#007DBC"
              />
            </svg>
          )}
          Ver outro
        </button>
      )}
    </footer>
  )
}
