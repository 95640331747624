import { useCallback, useContext, useEffect, useState } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import GiftCardModal from 'src/components/ui/Modal'
import ProductCard from 'src/components/restructure/product/ProductCard'
import type { Product } from 'src/components/restructure/product/types/product'
import Section from 'src/components/sections/Section'
import { RecommendationContext } from 'src/components/restructure/product/contexts/recommendations-context'
import axios from 'axios'
import {
  makeViewListImpressionEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import { InView } from 'react-intersection-observer'
import { buildProductListObject } from 'src/utils/restructure/analytics/amplitude/buildProductListObject'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { useLinxProductClick } from 'src/sdk/linx/hooks/useLinxProductClick'
import { useLinxUser } from 'src/sdk/linx/hooks/useLinxUser'
import { useMobile } from 'src/hooks/useMobile'

import ShelfBoughtTogetherFooter from './ShelfBoughtTogetherFooter/ShelfBoughtTogetherFooter'
import { useShelfBoughtTogether } from './useShelfBoughtTogether'
import type { ActiveItem } from './utils/types'
import './index.scss'

type Props = {
  type?: string
}

export function ShelfBoughtTogether({ type }: Props) {
  const { isMobile } = useMobile()
  const { sendProductClickEvent } = useLinxProductClick()
  const { loading } = useContext(RecommendationContext)
  const [eventData, setEventData] = useState<any[]>([])
  const standardObj = getStandardObject()
  const { getLinxUser } = useLinxUser()

  const linxUser = getLinxUser()

  const title = 'Compre junto'

  const {
    dataLoaded,
    itemsActive,
    currentItem,
    decimalValue,
    centsValue,
    inst,
    recommendations,
    productBuyTogether,
    openGiftCardAlert,
    setOpenGiftCardAlert,
    handleDeleteCard,
    handleChangedItemArraySKU,
    handleAddItemsToCart,
    handleReloadCard,
    checkQuantity,
    checkItemsActiveLength,
    allItemsLength,
  } = useShelfBoughtTogether()

  const handleChange = useCallback(
    (item: ActiveItem | undefined) => {
      if (item) {
        handleChangedItemArraySKU(item)
      }
    },
    [handleChangedItemArraySKU]
  )

  const handleBuyTogether = () => {
    handleAddItemsToCart()

    itemsActive?.forEach((item) => {
      if (!item?.clickUrl) {
        return
      }

      sendProductClickEvent({
        clickUrl: item.clickUrl,
        interactionType: 'ADD_TO_CART',
      })
    })
  }

  const handleOnViewEvents = useCallback(() => {
    if (recommendations?.impressionUrl) {
      const linxParams = new URLSearchParams({
        ...(linxUser?.deviceId && { deviceId: linxUser.deviceId }),
        ...(linxUser && 'id' in linxUser && { userId: linxUser.id }),
        source: isMobile ? 'mobile' : 'desktop',
      }).toString()

      axios.get(`${recommendations?.impressionUrl}&${linxParams}`)
    }

    if (!dataLoaded || eventData.length < 3) {
      return
    }

    const event = makeViewListImpressionEvent(
      eventData.map((item) => ({
        product: item,
        productItem: item?.items[0],
      })),
      {
        listName: `vitrine - Compre Junto${
          eventData[0]?.sport ? ` - ${eventData[0]?.sport}` : ''
        }`,
        dimension29: 'linx',
      }
    )

    sendEvent(event)

    const productListObj = buildProductListObject({
      listType: 'vitrine',
      listName: title,
      productsCount: itemsActive.length + 1,
    })

    dispatchAmplitudeEvent({
      eventName: 'Product List Viewed',
      eventData: {
        ...standardObj,
        ...productListObj,
      },
    })
  }, [
    dataLoaded,
    eventData,
    isMobile,
    itemsActive.length,
    linxUser,
    recommendations?.impressionUrl,
    standardObj,
  ])

  useEffect(() => {
    if (
      dataLoaded &&
      JSON.stringify(eventData) !== JSON.stringify(productBuyTogether)
    ) {
      setEventData(productBuyTogether)
    }
  }, [productBuyTogether, dataLoaded, eventData])

  if (!checkQuantity() || loading) {
    return null
  }

  return (
    <>
      {checkQuantity() && !loading && (
        <div>
          <GiftCardModal
            message="Não é possível comprar o Cartão Presente junto de outros produtos. Inicie uma nova compra para realizar a ação."
            openModal={openGiftCardAlert}
            setOpenModal={setOpenGiftCardAlert}
            title="AVISO!"
          />
          {dataLoaded ? (
            <InView
              key={currentItem?.productId}
              as="div"
              triggerOnce
              onChange={(inView) => {
                if (inView) {
                  handleOnViewEvents()
                }
              }}
            >
              <Section className="max-w-[1280px] m-auto px-md my-[40px] restructure-tablet:my-[48px] restructure-small-desktop:my-[60px]">
                <h2 className="mobile-heading-title3 tablet-heading-title3 desktop-heading-title3 text-restructure-primary mb-6 restructure-medium-desktop:mb-8">
                  Compre junto
                </h2>
                <div className="section-bought-together-list flex gap-[32px]  restructure-tablet:gap-[32px] restructure-medium-desktop:gap-[24px] justify-center restructure-medium-desktop:justify-start items-start flex-col restructure-medium-desktop:flex-row">
                  <ul className="new-bought-together-list pb-[25px] px-0 max-w-[100%] overflow-x-auto overflow-y-hidden justify-start flex gap-[10px] restructure-tablet:gap-[24px] restructure-medium-desktop:justify-center w-full restructure-medium-desktop:w-[unset]">
                    <li
                      className={`item-${
                        itemsActive.length + 1
                      } flex flex-col justify-between`}
                    >
                      <ProductCard
                        product={currentItem}
                        index={0}
                        shelfRef={`vitrine - Compre Junto${
                          currentItem?.sport ? ` - ${currentItem?.sport}` : ''
                        }`}
                        shelfEng="linx"
                        isShelfBoughtTogether
                        viewSelectVariation
                        amplitudeData={{ totalCountOfRegisters: 1 }}
                        type={type}
                      />

                      <p className="text-restructure-tertiary text-center mt-1 mobile-caption-regular desktop-body-regular-text3 desktop-body-regular-text3">
                        Você está vendo este produto
                      </p>
                    </li>
                    {itemsActive.map((product, index) => (
                      <li
                        key={product.productId}
                        className={`item-${
                          itemsActive.length + 1
                        } flex flex-col justify-between`}
                      >
                        <ProductCard
                          product={product as unknown as Product}
                          handleChangedItemSKU={handleChange}
                          index={index}
                          shelfRef={`vitrine - Compre Junto - ${product.sport}`}
                          shelfEng="linx"
                          isShelfBoughtTogether
                          viewSelectVariation
                          amplitudeData={{
                            totalCountOfRegisters: itemsActive.length,
                          }}
                          type={type}
                        />
                        <ShelfBoughtTogetherFooter
                          onDeleteCard={() =>
                            handleDeleteCard(product.productId)
                          }
                          onReloadCard={() =>
                            handleReloadCard(product.productId)
                          }
                          showButtonSeeAnother={
                            allItemsLength > itemsActive.length
                          }
                        />
                      </li>
                    ))}
                  </ul>

                  <div
                    className={`${checkItemsActiveLength(
                      itemsActive.length
                    )}  gap-y-2 flex flex-col justify-center w-full restructure-medium-desktop:w-none max-w-none restructure-medium-desktop:min-w-[303px] restructure-medium-desktop:max-w-[303px]`}
                  >
                    <div className="flex-col restructure-tablet:flex-row restructure-medium-desktop:flex-col border border-solid border-restructure-border-secondary rounded-md p-4 restructure-medium-desktop:py-10 restructure-medium-desktop:px-8 flex justify-between items-center restructure-medium-desktop:block">
                      <p className="mb-4 restructure-tablet:mb-0 restructure-medium-desktop:mb-4 text-restructure-secondary mobile-heading-title5 tablet-heading-title5 desktop-heading-title5 text-center">
                        Que tal levar os {itemsActive.length + 1} produtos?
                      </p>
                      <div>
                        <p className="flex justify-center mb-2">
                          <span className="mobile-heading-title3 tablet-heading-title3 desktop-heading-title3 uppercase">
                            R$ {decimalValue},{centsValue?.slice(0, 2)}
                          </span>
                        </p>
                        <p className="mb-8 restructure-tablet:mb-0 restructure-medium-desktop:mb-8 underline text-center text-restructure-tertiary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3">
                          {inst && inst.installment}x R${' '}
                          {inst && inst.installmentPrice}
                        </p>
                      </div>
                      <button
                        className="flex justify-center items-center gap-2 w-full rounded-round py-3 px-12 max-w-[239px] text-restructure-primary-inverted bg-restructure-background-action-1 hover:bg-restructure-background-action-1-hover active:bg-restructure-background-action-3 disabled:bg-restructure-background-action-4"
                        data-store-buy-button
                        name="buy together"
                        aria-label="buy together"
                        onClick={handleBuyTogether}
                      >
                        Comprar junto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.04102 10C4.04102 9.65482 4.32084 9.375 4.66602 9.375H16.3327C16.6779 9.375 16.9577 9.65482 16.9577 10C16.9577 10.3452 16.6779 10.625 16.3327 10.625H4.66602C4.32084 10.625 4.04102 10.3452 4.04102 10Z"
                            fill="white"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.0581 3.72456C10.3021 3.48048 10.6979 3.48048 10.9419 3.72456L16.7753 9.5579C17.0194 9.80197 17.0194 10.1977 16.7753 10.4418L10.9419 16.2751C10.6979 16.5192 10.3021 16.5192 10.0581 16.2751C9.81398 16.031 9.81398 15.6353 10.0581 15.3912L15.4495 9.99984L10.0581 4.60845C9.81398 4.36437 9.81398 3.96864 10.0581 3.72456Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </Section>
            </InView>
          ) : (
            <ProductShelfSkeleton />
          )}
        </div>
      )}
    </>
  )
}
