import type { FieldError, UseFormRegister } from 'react-hook-form'

interface Props {
  name: keyof {
    name: string
    email: string
    lastname?: string
    gender?: string
    mobile?: string
    dateBirth?: string
    document?: string
  }
  label?: string
  className?: string
  classContainer?: string
  mask?: string
  errors: FieldError | undefined
  register: UseFormRegister<{
    name: string
    email: string
    lastname?: string
    gender?: string
    mobile?: string
    dateBirth?: string
    document?: string
  }>
}
type InputProps = JSX.IntrinsicElements['input'] & Props
export function Input({
  name,
  label,
  className,
  classContainer,
  register,
  errors,
  ...rest
}: InputProps) {
  return (
    <div className={`flex flex-col sm:w-1/2 w-full ${classContainer}`}>
      {label && <label className="text-[#333] pb-2 ">{label}</label>}
      <input
        className={` border ${className} `}
        {...register(name)}
        name={name}
        aria-invalid={!!errors}
        {...rest}
      />
      {errors && (
        <span className="mx-0 my-3 px-3 py-0 text-xs font-bold bg-danger text-white w-fit relative before:content-[''] before:absolute before:top-[-1px] before:left-0 before:block before:h-0 before:w-0 before:ml-[2px] before:align-middle before:border-t-4 before:border-r-4 before:border-b-4 before:border-l-4 before:rotate-45 before:border-danger">
          {errors.message}
        </span>
      )}
    </div>
  )
}
