/**
 * Extracts the YouTube video ID from a given URL.
 *
 * Supports various YouTube URL formats, including:
 * - Standard: https://www.youtube.com/watch?v=VIDEO_ID
 * - Short: https://youtu.be/VIDEO_ID
 * - Embed: https://www.youtube.com/embed/VIDEO_ID
 *
 * @param {string} url - The YouTube video URL.
 * @returns {string | null} The video ID if found, or `null` if the URL is invalid.
 *
 * @example
 * getYouTubeVideoID("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
 * // Returns: "dQw4w9WgXcQ"
 *
 * @example
 * getYouTubeVideoID("https://youtu.be/dQw4w9WgXcQ");
 * // Returns: "dQw4w9WgXcQ"
 */
export function getYouTubeVideoID(url: string) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/

  const match = url.match(regex)

  return match ? match[1] : null
}

/**
 * Checks if a given URL is a YouTube video link.
 *
 * Supports various YouTube URL formats, including:
 * - Standard: https://www.youtube.com/watch?v=VIDEO_ID
 * - Short: https://youtu.be/VIDEO_ID
 * - Embed: https://www.youtube.com/embed/VIDEO_ID
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} `true` if it's a YouTube video URL, otherwise `false`.
 *
 * @example
 * isYouTubeVideo("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
 * // Returns: true
 *
 * @example
 * isYouTubeVideo("https://youtu.be/dQw4w9WgXcQ");
 * // Returns: true
 *
 * @example
 * isYouTubeVideo("https://www.example.com/watch?v=dQw4w9WgXcQ");
 * // Returns: false
 */
export default function isYouTubeVideo(url: string): boolean {
  const regex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)/

  return regex.test(url)
}
