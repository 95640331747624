import type { FieldError, UseFormRegister } from 'react-hook-form'

import ArrowsSelectIcon from '../../../images/icons/ArrowsSelectIcon'

interface SelectProps {
  name: keyof {
    name: string
    email: string
    lastname?: string
    gender?: string
    mobile?: string
    dateBirth?: string
    document?: string
  }
  label: string
  children: React.ReactNode
  classContainer: string
  errors?: FieldError
  register: UseFormRegister<{
    name: string
    email: string
    lastname?: string
    gender?: string
    mobile?: string
    dateBirth?: string
    document?: string
  }>
}

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & SelectProps

export function Select({
  name,
  label,
  children,
  classContainer,
  disabled,
  errors,
  register,
  ...rest
}: Props) {
  return (
    <div className={`flex flex-col sm:w-6/12 w-full ${classContainer}`}>
      <label className="mb-2">{label}</label>

      <div className="relative">
        <div
          className={`w-full border-neutral04 border-[1px]  ${
            !disabled && 'hover:border-[#A4ADB7]'
          } select-container relative`}
        >
          <select
            id={name}
            className={`w-full appearance-none opacity-100 ${
              disabled ? ' bg-[#FAFAFA]' : ' bg-[#fff]'
            } py-5 px-4  focus:outline-[#007dbc] font-inter text-base font-normal`}
            disabled={disabled}
            {...register(name)}
            {...rest}
          >
            {children}
          </select>

          {!disabled && (
            <div className="absolute inset-y-0 right-0 flex items-center mr-4 pointer-events-none w-5 h-5 top-1/2 transform -translate-y-1/2 ">
              <ArrowsSelectIcon />
            </div>
          )}
        </div>
      </div>

      {errors && <span className="error">{errors.message}</span>}
    </div>
  )
}
