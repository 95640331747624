import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import isYouTubeVideo from 'src/utils/youtube'

import { GalleryImageDesktop } from './GalleryDesktop'
import { GalleryImageMobile } from './GalleryMobile'

export const GalleryImage = () => {
  const { currentSku } = useContext(ProductContext)

  const { images, videos } = currentSku

  const getVideoObject = () => {
    if (videos.length < 1) {
      return undefined
    }

    return {
      url: videos[0],
      videoType: isYouTubeVideo(videos[0]) ? 'youtube' : 'brightcove',
      label: currentSku.name,
    }
  }

  const video = getVideoObject()

  return (
    <>
      {images && (
        <>
          <GalleryImageMobile images={images} video={video} />
          <GalleryImageDesktop images={images} video={video} />
        </>
      )}
    </>
  )
}
