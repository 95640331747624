import { ModalCommercialsBenefits } from 'src/components/sections/ProductDetails/ModalCommercialsBenefits'
import { useState } from 'react'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import type { CommercialBenefit } from 'src/components/product-page/context/types/product.type'
import DktSlider from 'src/components/restructure/common/DktSlider/DktSlider'

import { useProductPageContext } from '../../contexts'
import { BenefitsItem } from './BenefitsItem'

export function CommercialBenefits() {
  const {
    product: { commercialsBenefits },
  } = useProductPageContext()

  const [isOpen, setIsOpen] = useState(false)
  const [dataModal, setDataModal] = useState(commercialsBenefits[0])

  const handleOnClick = (commercialBenefit: CommercialBenefit) => {
    setDataModal(commercialBenefit)
    setIsOpen(true)

    const event = makeEventTrack({
      eventAction: 'saiba mais',
      eventPage: 'pagina de produto',
    })

    sendEvent(event)
  }

  return (
    <div className="restructure-tablet:py-sm restructure-tablet:px-md mt-14 mb-lg restructure-tablet:border restructure-tablet:border-solid restructure-tablet:border-[#D9DDE1] restructure-tablet:bg-restructure-background-primary md:h-[75px]">
      {commercialsBenefits.length > 1 ? (
        <DktSlider
          direction="vertical"
          showControls={false}
          centerItems={false}
          autoplayInterval={5000}
          sensitivity={5}
          rewind={false}
          sliderId="slider-commercial-benefits"
        >
          {commercialsBenefits.map((item, index) => {
            return (
              <BenefitsItem
                key={index}
                item={item}
                onClickButton={(e) => {
                  handleOnClick(e)
                }}
              />
            )
          })}
        </DktSlider>
      ) : (
        <div className="flex items-start gap-xs ">
          <BenefitsItem
            item={commercialsBenefits?.[0]}
            onClickButton={(e) => {
              handleOnClick(e)
            }}
          />
        </div>
      )}
      <ModalCommercialsBenefits
        handleCloseCommercialsBenefitsModal={setIsOpen}
        openModalCommercialsBenefits={isOpen}
        comercialBenefitsData={dataModal}
      />
    </div>
  )
}
