import ReactInputMask from 'react-input-mask'
import type { Props as InputProps } from 'react-input-mask'
import type { FieldError, UseFormRegister } from 'react-hook-form'

interface Props extends InputProps {
  name: keyof {
    cep?: string
  }
  label?: string
  className?: string
  classNameLabel?: string
  errors: FieldError | undefined
  register: UseFormRegister<{
    cep?: string
  }>
  id?: string
}
export function InputMask({
  name,
  label,
  className,
  classNameLabel,
  defaultValue,
  mask,
  id,
  register,
  errors,
}: Props) {
  return (
    <label className={classNameLabel}>
      {label && <p>{label}</p>}
      <ReactInputMask
        id={id}
        mask={mask ?? ''}
        className={`py-2 px-4 border rounded text-neutral06 ${className}`}
        defaultValue={defaultValue}
        {...register(name)}
      />
      {errors && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className="inline mr-1"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.3335 7.99967C1.3335 11.6817 4.31816 14.6663 8.00016 14.6663C11.6822 14.6663 14.6668 11.6817 14.6668 7.99967C14.6668 4.31767 11.6822 1.33301 8.00016 1.33301C4.31816 1.33301 1.3335 4.31767 1.3335 7.99967ZM13.3335 7.99967C13.3335 10.9452 10.9457 13.333 8.00016 13.333C5.05464 13.333 2.66683 10.9452 2.66683 7.99967C2.66683 5.05416 5.05464 2.66634 8.00016 2.66634C10.9457 2.66634 13.3335 5.05416 13.3335 7.99967ZM8.66683 9.99967V11.333H7.3335V9.99967H8.66683ZM8.66683 8.66634V4.66634H7.3335V8.66634H8.66683Z"
              fill="#E3262F"
            />
          </svg>
          <span className="inline mt-2 text-danger text-xs">
            {errors.message}
          </span>
        </>
      )}
    </label>
  )
}
