import { Link } from 'gatsby'
import type { CommercialBenefit } from 'src/components/product-page/context/types/product.type'
import { Image } from 'src/components/ui/Image'

type Props = {
  item: CommercialBenefit
  onClickButton: (item: CommercialBenefit) => void
}

export function BenefitsItem({ item, onClickButton }: Props) {
  return (
    <div className="flex gap-2">
      <Image
        className="self-start mt-1"
        src={item.icon}
        alt={item.name}
        width={24}
        height={24}
      />
      <div>
        <p className="desktop-body-regular-text3 text-restructure-primary">
          {item.text}
        </p>
        {item.link.action === 'open-modal' ? (
          <button
            onClick={() => onClickButton(item)}
            className="desktop-caption-regular text-restructure-tertiary underline mt-2"
          >
            {item.link.text}
          </button>
        ) : (
          <Link
            to={item.link.url ?? ''}
            className="desktop-caption-regular text-restructure-tertiary underline mt-2"
          >
            {item.link.text}
          </Link>
        )}
      </div>
    </div>
  )
}
